import React from 'react'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import SkutopiaBody from '../../components/story/skutopia/Content'
import SkutopiaHeader from '../../components/story/skutopia/Header'
import MetaImage from '../../assets/images/story/skutopia/cover.png'
import QuoteBlock from '../../components/blocks/QuoteBlock'

const SkutopiaPage = () => {
  return (
    <Layout
      title="SKUTOPIA case study"
      description="SKUTOPIA uses Swarmia to make sure the fast-growing SaaS company’s engineering organization is a great place to work not only today but also well into the future."
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <SkutopiaHeader />
        <QuoteBlock person="dan" />
        <SkutopiaBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best teams get better. Get started today.
      </CTASection>
    </Layout>
  )
}
export default SkutopiaPage
