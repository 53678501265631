import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const SkutopiaHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      company="SKUTOPIA"
      heading="How SKUTOPIA combines survey data and engineering metrics to scale developer experience"
      subheading="SKUTOPIA uses Swarmia to make sure the fast-growing SaaS company’s engineering organization is a great place to work not only today but also well into the future."
      featured={featured}
    />
  )
}

export default SkutopiaHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/skutopia/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
